@import '../../../../scss/theme-bootstrap';

.product-grid-recommendations-formatter {
  @include shade-picker-style;
  margin: 30px 0;
  .sd-product-spp & {
    .sd-product-grid {
      padding: 0;
      .elc-product-brief {
        padding: 0;
      }
    }
  }
  .sd-product-grid {
    @include container-padding-rules;
    @include container-max-width-x-large;
    margin: auto;
    min-height: 500px;
    .elc-product-carousel-container {
      .elc-product-carousel-slider-view {
        margin: 0;
      }
    }
  }
  .elc-product-grid-wrapper {
    margin: 0;
  }
  .elc-product-carousel-container {
    margin: 0;
    .elc-slider-view-wrapper {
      padding: 0;
    }
    .slick-list {
      margin: 0 -12px;
      .slick-slide {
        margin: 0;
        padding: 0 12px;
      }
    }
  }
  .elc-product-brief-view {
    margin: 0 -12px;
  }
  .elc-grid-item-product {
    padding: 0 12px;
  }
  .elc-product-shade-picker-slider {
    .slick-slider,
    .slick-list {
      margin: 0;
    }
  }
  .elc-size-picker-container {
    .elc-slider-view-wrapper {
      margin: 10px 0;
      padding: 0;
    }
    .slick-slider,
    .slick-list {
      margin: 0;
    }
    .slick-list {
      .slick-slide {
        padding: 0;
        &:last-of-type {
          .elc-size-picker-box {
            margin: 0;
          }
        }
      }
    }
  }
  .sd-mount-skeleton {
    .sd-grid-skeleton {
      margin: 0 -12px;
      padding: 0;
      .sd-grid-skeleton-items {
        &.is-slider {
          margin: 0;
          .skeleton-item {
            padding: 0 12px 24px;
          }
        }
      }
      .sd-grid-skeleton-filters {
        padding-#{$ldirection}: 12px;
        padding-#{$rdirection}: 12px;
      }
    }
  }
}
.spp-product-layout__content-item {
  .clearfix {
    &:has(.product-grid-recommendations-formatter) {
      overflow: visible;
    }
  }
}
